export class MenuConstants {
  public static CLONE_TO_PLAN = "Copy to Plan";
  public static ADD_HOARDINGS = "Add Hoarding(s)";
  public static VIEW_IMAGES = "Slideshow";
  public static XLSX = "XLSX";
  public static DOWNLOAD = "Download";
  public static DOWNLOAD_ALL = "Download All";
  public static PHOTOS = "Photos";
  public static DOWNLOAD_XLSX_L = "Download XLSX";
  public static STANDARD_XLSX_DOWNLOAD = "Standard XLSX Download";
  public static DOWNLOAD_XLSX_WITH_LSP = "XLSX with LSP Data";
  public static DOWNLOAD_XLSX_WITH_LATITUDE_AND_LONGITUDE = "XLSX with Latitude & Longitude";
  public static GENERATE_AND_SEND_XLSX = "Generate and Send Xlsx";
  public static DOWNLOAD_XLSX_I = "fa fa-file-excel-o";
  public static DOWNLOAD_PHTOTS_L = "Download Photos";
  public static DOWNLOAD_PHTOTS_I = "fa-image";
  public static DOWNLOAD_PPT_L = "Download PPT";
  public static DOWNLOAD_PPT_L_S1 = "Download PPT(Server 1)";
  public static DOWNLOAD_FULL_DETAIL = "Full Detail";
  public static DOWNLOAD_FULL_DETAIL_COMPRESS = "Full Detail(Compress)";
  public static DOWNLOAD_LESS_DETAIL = "Less Detail";
  public static DOWNLOAD_LESS_DETAIL_COMPRESS = "Less Detail(Compress)";
  public static DOWNLOAD_CUSTOMER_TEMPLATE = "Customer Template";
  public static DOWNLOAD_VENDOR_TEMPLATE = "Company Template";
  public static FULL_DETAIL_PPT = "Full Detail PPT";
  public static LESS_DETAIL_PPT = "Less Detail PPT";
  public static VENDOR_TEMPLATE_PPT = "Company Template PPT";
  public static CUSTOMER_TEMPLATE_PPT = "Customer Template PPT";

  // public static DOWNLOAD_PPT_L_S2 = "Download PPT(Server 2)";
  public static DOWNLOAD_PPT_L_S2 = "Download PPT";
  public static DOWNLOAD_PPT_L_S2_OLD = "Download PPT OLD";
  public static GENERATE_AND_SEND = "Generate and Send";
  public static DOWNLOAD_PPT_L_BETA = "Download PPT Beta";
  public static DOWNLOAD_PPT_I = "fa-file-powerpoint-o";
  public static SEND_L = "Send Email";
  public static SEND_I = "fa-envelope";
  public static EDIT_L = "Edit";
  public static EDIT_CONTINUE_L = "Edit & Continue";
  public static EDIT_I = "fa-pencil";
  public static CANCEL_L = "Cancel";
  public static CANCEL_RESTORE_L = "Cancel & Restore";
  public static CANCEL_I = "fa-ban";
  public static DISCARD_L = "Discard";
  public static DELETE_M = "Delete";
  public static DISCARD_I = "fa-close";
  public static APPLY_CN_L = "Full CN";
  public static APPLY_CN_I = "fa-circle";
  public static APPLY_PARTIALLY_CN_L = "Part CN";
  public static APPLY_PARTIALLY_CN_I = "fa-adjust";
  public static UPDATE_INVOICE_L = "Update";
  public static UPDATE_INVOICE_I = "fa-edit";
  public static COPY_PLAN = "Copy Plan";
  public static SHARE_PLAN = "Share Plan";
  public static PLAN_TRACKER = "Plan Tracker";
  public static SHARE_CAMPAIGN_AS_PLAN = "Share Campaign ";
  public static CAMPAIGN_TRACKER = "Campaign Tracker";
  public static QUICK_UPDATE_INVOICE_L = "Quick Update";
  public static CONFIRMATION_QUOTE_L = "Confirmation Quote";
  public static CONFIRMATION_QUOTE_I = "fa fa-file-pdf-o";
  public static UPDATE_INVENTORY_WITH_PHOTOS_L = "Update Inventory Photos";
}
