export const environment = {
  production: false,
  VERSION: require('../../package.json').version,
  name: 'qa',
  masterUrl: 'https://devapi1.showitbig.com:9001/master-api',
  baseUrl: 'https://devapi1.showitbig.com:9003/api/',
  dummyUrl: 'http://localhost:3000/',
  helpUrl: 'https://chat.showitbig.com:9002/article-api/',
  chatBaseURL: 'https://chat.showitbig.com:9001',

  // @Divya -- commented this temporary due to M2M calls limit exhausted in this tenant , once resolved uncomment this config to comment the other config
  // this change is in 2 files, enviroment.ts and environment.qa.ts

  // masterConfigCredentials: {
  //   masterTokenUrl: 'https://showitmax-master-dev-qa.us.auth0.com/oauth/token',
  //   config: {
  //     "grant_type": "client_credentials",
  //     "audience": "sim-master-dev-qa-api",
  //     "client_id": "pNv6pPPgnoVg2YJ6oHeCEuXRjLK8vGNn",
  //     "client_secret": "RMA5cK6lQ7A2VXrBo6OftwbJgcSQ1Vr1QnaBUYa2cwaQL6030PN0cU4gvcW65t5u"
  //   }
  // }

  // masterConfigCredentials: {
  //   masterTokenUrl: 'https://showitbig-master.auth0.com/oauth/token',
  //   config: {
  //     "grant_type": "client_credentials",
  //     "audience": "sib-master-api",
  //     "client_id": "wQ6Rnzq6lKaAffc4sNwXmAhmydj8nrSq",
  //     "client_secret": "XwoJbpaJezX2tN_eLEZUV9TZ_zVG4HKxx6w4TVjfMCXfQeVUqd2UGRxTNbdtI36k"
  //   }
  // }

  // masterConfigCredentials: {
  //   masterTokenUrl: 'https://sim-master-dev-qa.us.auth0.com/oauth/token',
  //   config: {
  //     "grant_type": "client_credentials",
  //     "audience": "sim-master-dev-qa-api",
  //     "client_id": "t381wa8Ebgiy9AfznzV8XLKVNuFZ3X0s",
  //     "client_secret": "9wtJPceoqcHqB5-rIhHFEX75OHkP1YR6Zy6NW-6mSea4X81OGd4rb558ushCYM2n"
  //   }
  // }

  // masterConfigCredentials: {
  //   masterTokenUrl: 'https://showitmax-master-dev-qa-backup.us.auth0.com/oauth/token',
  //   config: {
  //     "grant_type": "client_credentials",
  //     "audience": "sim-master-dev-qa-api",
  //     "client_id": "JgYGRO7QZMDwebFMB99XyGwed699pb9R",
  //     "client_secret": "i7rHJwWKE70uvw-FcaBDbeCToi0HtbZSb-ezcpkOZ7JXDWQpb-zfy16s4CqeYWcZ"
  //   }
  // }

  masterConfigCredentials: {
    masterTokenUrl: 'https://showitmax-master-dev-qa-backup-new.us.auth0.com/oauth/token',
    config: {
      "grant_type": "client_credentials",
      "audience": "sim-master-dev-qa-api",
      "client_id": "lefdIxqHoiBMVJss12br4ev7rLzEN3ec",
      "client_secret": "71wu90hbdtue1T1ZxlkEXuBJLzi1u-Eq7dHsJzuyQJ46AVI7YCWzKK9kqJGP6QUq"
    }
  }

  // masterConfigCredentials: {
  //   masterTokenUrl: 'https://showitmax-master-dev-qa-backup-backup2.us.auth0.com/oauth/token',
  //   config: {
  //     "grant_type": "client_credentials",
  //     "audience": "sim-master-dev-qa-api",
  //     "client_id": "QmXuwKsVRqOVQnVO8qyFvQVFAGZ6Ed3b",
  //     "client_secret": "LuFs6RjCbxyPV-a6RnNEGKHKs-RD9Y6GXR4xDgjlStk4MW3OApv_vRHk1KOHfPiY"
  //   }
  // }
};




