<div *ngIf="isloading">
  <p-progressSpinner class="loading"></p-progressSpinner>
</div>
<div class="profile ui-g-12 card">
  <form #userProfileForm="ngForm">
    <div class="ui-g-12 profile-container">
      <div class="name-container ui-g-12">
        <sib-back></sib-back>
        <h2>{{ firstName }} {{ lastName }}</h2>
      </div>
      <div class="ui-g-12 padding-left-5">
        <div class="ui-g-4">
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
              <input
                id="firstname"
                type="text"
                minlength="0"
                maxlength="10"
                pInputText
                [(ngModel)]="user.firstName"
                name="firstname"
                [pattern]="pattern"
                required
              />
              <label for="firstname"
                >First Name <span class="required">*</span></label
              >
              <span
                class="info-red error-message"
                *ngIf="checkValidation(user.firstName)"
              >
                * Special characters and numbers are not allowed.
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
              <input
                id="lastname"
                type="text"
                minlength="0"
                maxlength="10"
                pInputText
                [(ngModel)]="user.lastName"
                name="lastname"
                [pattern]="pattern"
                required
              />
              <label for="lastname"
                >Last Name <span class="required">*</span></label
              >
              <span
                class="info-red error-message"
                *ngIf="checkValidation(user.lastName)"
              >
                * Special characters and numbers are not allowed.
              </span>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
              <input
                id="number"
                type="text"
                minlength="0"
                maxlength="10"
                pInputText
                [(ngModel)]="user.number"
                name="number"
                required
              />
              <label for="number">Phone <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
              <input
                disabled
                id="email"
                type="text"
                minlength="0"
                maxlength="10"
                pInputText
                [(ngModel)]="user.emailId"
                name="email"
              />
              <label for="email">Email</label>
            </span>
          </div>
        </div>
        <div class="additional-info-container ui-g-7">
          <div class="info ui-g-12">
            <h3>Additional Information</h3>
          </div>
          <div class="ui-g-12">
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-dropdown
                    id="gender"
                    [autoWidth]="false"
                    [(ngModel)]="user.gender"
                    [autoDisplayFirst]="false"
                    [options]="genders"
                    name="gender"
                  ></p-dropdown>
                  <label for="gender">Gender </label>
                </span>
              </div>

              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-calendar
                    #dob
                    id="dob"
                    appendTo="body"
                    name="dateofbirth"
                    dateFormat="ddMy"
                    [(ngModel)]="user.dateOfBirth"
                    monthNavigator="true"
                    yearNavigator="true"
                    [yearRange]="'1950:2018'"
                    [utc]="true"
                    [showIcon]="true"
                  ></p-calendar>
                  <label for="dob">DOB</label>
                </span>
              </div>

              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-dropdown
                    id="blood-Group"
                    [autoWidth]="false"
                    [(ngModel)]="user.bloodGroup"
                    [autoDisplayFirst]="false"
                    [options]="bloodGroups"
                    name="bloodGroup"
                  ></p-dropdown>
                  <label for="blood-Group">Blood Group </label>
                </span>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-autoComplete
                    [(ngModel)]="user.state"
                    [suggestions]="filteredStateList"
                    (completeMethod)="filterStateList($event)"
                    (onSelect)="onStateSelect()"
                    styleClass="wid10"
                    [minLength]="0"
                    field="name"
                    [multiple]="false"
                    [dropdown]="true"
                    name="states"
                    forceSelection="true"
                  >
                  </p-autoComplete>
                  <label>State</label>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-autoComplete
                    [suggestions]="filteredDistrictList"
                    (completeMethod)="filterDistrictList($event)"
                    [(ngModel)]="user.district"
                    (onSelect)="onSelectDistrict()"
                    styleClass="wid10"
                    [minLength]="0"
                    field="name"
                    [multiple]="false"
                    [dropdown]="true"
                    name="districts"
                    forceSelection="true"
                  >
                  </p-autoComplete>
                  <label>District</label>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                  <p-autoComplete
                    [suggestions]="filteredCitiesList"
                    (completeMethod)="filterCityList($event)"
                    [(ngModel)]="user.city"
                    styleClass="wid10"
                    [minLength]="0"
                    field="name"
                    [multiple]="false"
                    [dropdown]="true"
                    name="cities"
                    forceSelection="true"
                  >
                  </p-autoComplete>
                  <label>City</label>
                </span>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                  <input
                    id="postalcode"
                    type="text"
                    minlength="6"
                    maxlength="6"
                    pInputText
                    [(ngModel)]="user.postalCode"
                    name="postalcode"
                  />
                  <label for="postalcode">Postal Code</label>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                  <input
                    id="emergencynumber"
                    type="text"
                    minlength="0"
                    maxlength="10"
                    pInputText
                    [(ngModel)]="user.emergencyContactNumber"
                    name="enumber"
                  />
                  <label for="emergencynumber">Emergency Number</label>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                  <input
                    id="emergencypersonname"
                    type="text"
                    pInputText
                    [(ngModel)]="user.emergencyContactPersonName"
                    name="econtactperson"
                  />
                  <label for="emergencypersonname">Contact Person</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="email-password-setup ui-g-12 padding-left-5">
      <div class="sib-credentials-container ui-g-4">
        <div class="ui-g-12">
          <h2 class="heading">ShowItMax Credentials</h2>
          <span class="heading-secondary"
            >These Credentials are used for logging into ShowItMax system.</span
          >
        </div>
        <div class="user-name ui-g-12">
          <span>User Name</span>
          <div>{{ user.emailId }}</div>
        </div>
        <div class="ui-g-12 password">
          <h2 class="heading">Change Password</h2>
        </div>

        <div class="ui-g-12 ui-fluid inputfield">
          <span class="md-inputfield ui-g-10">
            <!-- [pattern]="passwordPattern" -->
            <input
              #sibpassword
              #sibNewPassword="ngModel"
              id="password"
              [(ngModel)]="sibPassword"
              type="password"
              minlength="6"
              maxlength="15"
              pInputText
              (keyup)="verifySibPassword()"
              name="password"
              [pattern]="passwordPattern"
              pTooltip="{{
                sibNewPassword.hasError('pattern') ? passwordPolicy : ''
              }}"
              tooltipEvent="focus"
            />
            <label for="password">New Password</label>
            <i
              class="passwordVisibility"
              [ngClass]="
                password
                  ? password.nativeElement.type === 'text'
                    ? 'fa fa-eye'
                    : 'fa fa-eye-slash'
                  : 'fa fa-eye-slash'
              "
              (click)="togglePasswordVisibility('password')"
            ></i>
          </span>

          <!-- <span class="fa fa-info-circle margin-left-5 ui-g-1" [title]="passwordPolicy"></span> -->
        </div>
        <div class="ui-g-12 ui-fluid inputfield">
          <span class="md-inputfield ui-g-10">
            <input
              #confirmpassword
              #sibNewConfirmPassword="ngModel"
              id="confirmpassword"
              [class.ng-invalid]="sibPassword && !sibPasswordVerified"
              [disabled]="!sibPassword"
              [(ngModel)]="sibConfirmPassword"
              (keyup)="verifySibPassword()"
              type="password"
              minlength="6"
              maxlength="15"
              pInputText
              name="confirmpassword"
              [pattern]="passwordPattern"
              pTooltip="{{
                sibNewConfirmPassword.hasError('pattern')
                  ? passwordPolicy
                  : sibPassword !== sibConfirmPassword
                  ? 'New Password and Confirm Password does not match.'
                  : ''
              }}"
              tooltipEvent="focus"
            />
            <label for="confirmpassword">Confirm Password</label>
            <i
              class="passwordVisibility"
              [ngClass]="
                confirmPassword
                  ? confirmPassword.nativeElement.type === 'text'
                    ? 'fa fa-eye'
                    : 'fa fa-eye-slash'
                  : 'fa fa-eye-slash'
              "
              (click)="togglePasswordVisibility('confirmPassword')"
            ></i>
          </span>

          <!-- <span *ngIf="sibPassword && !sibPasswordVerified" class="required">Required field.</span> -->
        </div>
      </div>
      <div class="sib-email-setup ui-g-3">
        <div class="ui-g-12">
          <h2 class="heading">ShowItMax Email Setup</h2>
          <span class="heading-secondary"
            >Setup Email for Client Communication.</span
          >
        </div>

        <div class="personal-email-container ui-g-12 ui-fluid inputfield">
          <span>Organization Email</span>
          <p-radioButton
            name="emailgroup"
            value="orgEmail"
            label="{{ organizationEmail }}"
            [(ngModel)]="emailSelected"
          >
          </p-radioButton>
        </div>
      </div>
    </div>
  </form>
</div>
<div style="text-align: center">
  <button
    [disabled]="
      !userProfileForm.form.valid ||
      (sibPassword && !sibPasswordVerified) ||
      !emailSelected ||
      (emailSelected === 'userEmail' && !isUserEmailVerified) ||
      (emailSelected === 'userEmail' && !user.emailConfig.password)
    "
    (click)="updateUser()"
    class="mtab-save"
    type="button"
    label="UPDATE"
    pButton
  ></button>
</div>
