import { CustomLoader } from "../../modals/loader-subjects/loader";
import { PptConfig } from "../ppt/ppt-config";

export class PPTLoader extends CustomLoader {
  data?: any;
  fileType?: any;
  searchEvent?: any;
  customIds?: string[] = [];
  subModule?: any;
  source?: string;
  pptData?: any;
  billed?: any;
  invoiceType?: any;
  ids?: string[] = [];
  mediaType?: string;
  format?: string;
  pptUrls?: any;
  vendorPptConfig?: PptConfig;
  isLSPIncluded?:boolean;
  isLATLONGIncluded?:boolean;
}
